import React from "react";
import "../App.css";
// Replace with your own image path
import vanImage from "../img/van.JPEG";

const Intro = () => {
  return (
    <section className="intro-section">
      {/* Top Row: Van Image (left), Heading & Paragraph (right) */}
      <div className="top-row">
        <div className="image-container">
          <img src={vanImage} alt="Van" />
        </div>
        <div className="main-text">
          <h1>Fagan &amp; Greene</h1>
          <p>
            With over 20 years of combined experience in the domestic, 
            commercial, and industrial sectors, Fagan &amp; Greene 
            Electrical Contractors have established themselves as one 
            of the most reliable and trusted electrical contractors 
            in the region.
          </p>
        </div>
      </div>

      {/* Bottom Row: Two columns of text */}
      <div className="bottom-row">
        <div className="info-box">
          <h2>Our History</h2>
          <p>
            Founded in 2022, we are based in the South Meath area 
            but proudly serve much of Leinster. Despite being a 
            newly formed company, our commitment to reliability 
            and trustworthiness has quickly helped us build a 
            loyal client base across all our service areas, 
            including maintenance work.
          </p>
        </div>
        <div className="info-box">
          <h2>Why Choose Us?</h2>
          <p>
            We are fully insured and registered with Safe Electric. 
            If you're seeking dependable and professional electrical 
            services, don't hesitate to contact us.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Intro;
