import { useEffect } from 'react';
import React, { useRef } from 'react';
import '../CSS/SolarPV.css';
import solarImage from '../img/solarPanels.jpg'
import ContactForm from '../Sections/ContactForm';
const SolarPV = () => {

    const contactFormRef = useRef(null);

    const handleScrollToContactForm = () => {
        if(contactFormRef.current){
            contactFormRef.current.scrollIntoView({ behavior: 'smooth'});
        }
    }


    useEffect(() => {
        const sectionsToAnimate = document.querySelectorAll('.animate-on-scroll');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        sectionsToAnimate.forEach(section => {
            observer.observe(section);
        });

        // Clean-up function
        return () => {
            sectionsToAnimate.forEach(section => {
                observer.unobserve(section);
            });
        };
    }, []);


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const sectionToAnimate = document.querySelector('.solar-overview');
            const listsToAnimate = document.querySelectorAll('.animate-on-scroll, .combined-column li');
    
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            }, { threshold: 0.5 });
    
            // Observe the main section to make it visible
            if (sectionToAnimate) {
                observer.observe(sectionToAnimate);
            }
    
            // Observe list items for staggered animations
            listsToAnimate.forEach(list => {
                observer.observe(list);
            });
    
        }, 500); // Delay of 500ms, you can adjust this value as needed
    
        // Clean-up function to clear the timeout and disconnect observer
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);


    useEffect(() => {
        const sectionToAnimate = document.querySelector('.solar-hero');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.1 });

        if (sectionToAnimate) {
            observer.observe(sectionToAnimate);
        }

        // Clean-up function
        return () => {
            if (sectionToAnimate) {
                observer.unobserve(sectionToAnimate);
            }
        };
    }, []);
    

    return( 
        <div className='solar-pv-page'>
            {/* Hero Section */}
            <section className='solar-hero'>
                <div className='hero-content'>
                    <div className='text-container'>
                        <h1>Illuminate Your Future</h1>
                    </div>
                    <div className='image-container'>
                        <img src={solarImage} alt="solar panels" />
                    </div>
                </div>
            </section>


           {/* Overview Section */}
           <section className="solar-overview animate-on-scroll">
            <h2>Why Choose Us for Your Solar Panels?</h2>
            <p>Fagan & Greene are now offering turnkey solar PV installations to help reduce your electricity bills and harness the power of renewable energy.</p>
            <div className="benefits-registration-combined">
                <div className="combined-column">
                <h3>Benefits</h3>
                <ul>
                    <li>Reduce Electricity Bills</li>
                    <li>Hot Water Diverters</li>
                    <li>Competitive Rates</li>
                    <li>Reduce Carbon Footprint</li>
                </ul>
                </div>
                <div className="combined-column">
                <h3>Registration</h3>
                <ul>
                    <li>Fully Registered with Safe Electric</li>
                    <li>Fully Insured</li>
                    <li>Registered with SEAI</li>
                    <li>Reduce Electricity Bills</li>
                </ul>
                </div>
            </div>
            </section>

            {/* Assessment Section */}
            <section className="solar-assessment animate-on-scroll">
                <h2>Assessment</h2>
                <p>At Fagan & Greene, we are happy to offer a free on-site evaluation to assess the best solar options tailored to your needs, ensuring maximum efficiency and savings.</p>
                <button className="contact-button" onClick={handleScrollToContactForm}>Contact Us</button>
            </section>

            {/* SEAI Grants Section */}
            <section className="seai-grants animate-on-scroll">
            <h2>SEAI Grants</h2>
            <p>
                At Fagan &amp; Greene, we are fully registered with SEAI Grants. This allows us to take the hard frustration of applying for grants out of your hands and put them into ours. The SEAI grant promotes sustainable energy solutions while helping to offset the installations costs when installing Solar Photovoltaic panels.
            </p>
            <button 
                className="learn-more-button" 
                onClick={() => window.open("https://www.seai.ie", "_blank")}
            >
                Learn More
            </button>
            </section>


            <section ref={contactFormRef} id="contact-form" className='contact-form'>
                <ContactForm />
            </section>
        </div>
    );
};

export default SolarPV;