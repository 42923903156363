import React from "react";
import '../CSS/Gallery.css';
import work3 from '../img/work3.jpg';
import work5 from '../img/work5.jpg';
import work6 from '../img/work6.jpg';
import work7 from '../img/work7.jpg';
import work8 from '../img/work8.jpg';
import light from '../img/lightShade.png';
import work9 from '../img/work9.jpg';
import work10 from '../img/work10.jpg';
import charger from '../img/evChargerPhoto.jpg';
import work11 from '../img/light1.JPEG';
import work12 from '../img/light2.JPEG';
import work13 from '../img/light3.JPEG';
import work14 from '../img/solarPanel.JPEG';

const Gallery = () => {
  return (
    <div className="container">
      <h1 className="gallery-header">
        Get an Insight into our Work Through Our Gallery
      </h1>
      <div className="top">
        <ul>
          {/* Thumbnail links to the lightbox anchors */}
          <li>
            <a href="#img_1">
              <img src={work3} alt="Gallery 1" />
            </a>
          </li>
          <li>
            <a href="#img_2">
              <img src={work5} alt="Gallery 2" />
            </a>
          </li>
          <li>
            <a href="#img_3">
              <img src={work6} alt="Gallery 3" />
            </a>
          </li>
          <li>
            <a href="#img_4">
              <img src={work7} alt="Gallery 4" />
            </a>
          </li>
          <li>
            <a href="#img_5">
              <img src={work8} alt="Gallery 5" />
            </a>
          </li>
          <li>
            <a href="#img_6">
              <img src={work9} alt="Gallery 6" />
            </a>
          </li>
          <li>
            <a href="#img_7">
              <img src={work10} alt="Gallery 7" />
            </a>
          </li>
          <li>
            <a href="#img_8">
              <img src={charger} alt="Gallery 8" />
            </a>
          </li>
          <li>
            <a href="#img_9">
              <img src={work11} alt="Gallery 9" />
            </a>
          </li>
          <li>
            <a href="#img_10">
              <img src={work12} alt="Gallery 10" />
            </a>
          </li>
          <li>
            <a href="#img_11">
              <img src={work13} alt="Gallery 11" />
            </a>
          </li>
          <li>
            <a href="#img_12">
              <img src={work14} alt="Gallery 12" />
            </a>
          </li>
        </ul>

        {/* Lightbox anchors (the large pop-up images) */}
        <a href="#_1" className="lightbox trans" id="img_1">
          <img src={work3} alt="Gallery 1" />
        </a>
        <a href="#_2" className="lightbox trans" id="img_2">
          <img src={work5} alt="Gallery 2" />
        </a>
        <a href="#_3" className="lightbox trans" id="img_3">
          <img src={work6} alt="Gallery 3" />
        </a>
        <a href="#_4" className="lightbox trans" id="img_4">
          <img src={work7} alt="Gallery 4" />
        </a>
        <a href="#_5" className="lightbox trans" id="img_5">
          <img src={work8} alt="Gallery 5" />
        </a>
        <a href="#_6" className="lightbox trans" id="img_6">
          <img src={work9} alt="Gallery 6" />
        </a>
        <a href="#_7" className="lightbox trans" id="img_7">
          <img src={work10} alt="Gallery 7" />
        </a>
        <a href="#_8" className="lightbox trans" id="img_8">
          <img src={charger} alt="Gallery 8" />
        </a>
        <a href="#_9" className="lightbox trans" id="img_9">
          <img src={work11} alt="Gallery 9" />
        </a>
        <a href="#_10" className="lightbox trans" id="img_10">
          <img src={work12} alt="Gallery 10" />
        </a>
        <a href="#_11" className="lightbox trans" id="img_11">
          <img src={work13} alt="Gallery 11" />
        </a>
        <a href="#_12" className="lightbox trans" id="img_12">
          <img src={work14} alt="Gallery 12" />
        </a>
      </div>
    </div>
  );
};

export default Gallery;
