import React from "react";

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>
      Welcome to Fagan &amp; Greene Contractors' Privacy Policy. We are committed to safeguarding your privacy. This policy explains how we handle personal information collected on our website.
    </p>

    <h2>1. Information We Collect</h2>
    <p>
      We collect personal information such as your name, phone number, email address, and job description when you contact us via our website. This information is used to respond to your inquiries and provide you with a quote or further information about our services.
    </p>

    <h2>2. How We Use Your Information</h2>
    <p>
      Your information is used solely for the purpose of addressing your inquiry, managing our customer relationships, and improving our services. We do not use your data for any other purposes without your consent.
    </p>

    <h2>3. Data Storage and Security</h2>
    <p>
      We store your personal data on secure servers with strict access controls. We use industry-standard security measures, including encryption, to protect your information.
    </p>

    <h2>4. Data Sharing and Disclosure</h2>
    <p>
      We do not sell or rent your personal data to third parties. Your information may be shared with trusted service providers who assist us in managing our operations, but only to the extent necessary and under strict confidentiality agreements.
    </p>

    <h2>5. Data Retention</h2>
    <p>
      We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, or as required by law. Once your information is no longer needed, it will be securely deleted or anonymized.
    </p>

    <h2>6. Your Rights</h2>
    <p>
      You have the right to access, correct, or delete your personal data. If you wish to exercise any of these rights, please contact us using the details provided below.
    </p>

    <h2>7. Consent</h2>
    <p>
      By submitting your information through our contact form, you consent to the collection and use of your personal data as described in this policy. You may withdraw your consent at any time by contacting us.
    </p>

    <h2>8. Third-Party Links</h2>
    <p>
      Our website may contain links to third-party sites. Please be aware that we are not responsible for the privacy practices of these other sites. We encourage you to read the privacy statements of each website you visit.
    </p>

    <h2>9. Changes to This Policy</h2>
    <p>
      We may update our Privacy Policy periodically to reflect changes in our practices or legal requirements. Any changes will be posted on this page with an updated revision date.
    </p>

    <h2>10. Contact Us</h2>
    <p>
      If you have any questions or concerns about this Privacy Policy or our data practices, please contact us:
    </p>
    <p>Phone: 087 407 7240</p>
    <p>Email: info.fgcontractors@gmail.com</p>
  </div>
);

export default PrivacyPolicy;
