import React from "react";

const CookiesPolicy = () => (
  <div className="cookies-policy">
    <h1>Cookies Policy</h1>
    <p>
      This Cookies Policy explains how Fagan &amp; Greene Contractors uses cookies and similar technologies on our website to enhance your browsing experience.
    </p>

    <h2>1. What Are Cookies?</h2>
    <p>
      Cookies are small text files stored on your device by your web browser. They help us recognize you, remember your preferences, and improve your overall experience on our site.
    </p>

    <h2>2. How We Use Cookies</h2>
    <p>
      We use cookies for the following purposes:
    </p>
    <ul>
      <li>
        <strong>Essential Cookies:</strong> These cookies are necessary for the operation of our website. They enable you to navigate the site and use its features.
      </li>
      <li>
        <strong>Performance Cookies:</strong> These cookies collect information about how you use our website, such as which pages you visit most often. This data helps us improve the functionality and user experience.
      </li>
      <li>
        <strong>Functional Cookies:</strong> These cookies allow our website to remember choices you make (such as your username or language preferences) and provide enhanced, more personalized features.
      </li>
      <li>
        <strong>Analytical Cookies:</strong> In some cases, we may use analytical cookies to understand user behavior and measure website performance. These cookies help us improve our services.
      </li>
    </ul>

    <h2>3. Managing Cookies</h2>
    <p>
      Most web browsers allow you to manage your cookie settings. You can set your browser to alert you when cookies are being set, disable cookies, or delete existing cookies. For more information, please refer to your browser’s help documentation.
    </p>
    <p>
      Please note that disabling certain cookies may affect your ability to use some features of our website.
    </p>

    <h2>4. Changes to This Cookies Policy</h2>
    <p>
      We may update this Cookies Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page along with a new "Last Updated" date.
    </p>

    <h2>5. Contact Us</h2>
    <p>
      If you have any questions or concerns about our use of cookies, please contact us:
    </p>
    <p>Phone: 087 407 7240</p>
    <p>Email: info.fgcontractors@gmail.com</p>
  </div>
);

export default CookiesPolicy;
